<template>
  <div class="mb-10">
    <v-container>
      <v-container>
        <v-col class="d-flex">
          <!-- Row 1  -->
          <v-row justify="start">
            <v-list>
              <v-list-item-content>
                <h1 class="text-uppercase team-title">
                  {{ team.name_long }}
                </h1>

                <v-list-item-subtitle class="mt-5 py-1 team-date">
                  {{ team.date }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list>
          </v-row>
          <!-- Row 2 -->
          <v-row justify="end">
            <v-list>
              <v-btn
                outlined
                icon
                class="ml-2"
                @click.native="playing ? pause() : play()"
                :disabled="!loaded"
              >
                <v-icon v-if="!playing || paused">{{ playIcon }}</v-icon>
                <v-icon v-else>{{ pauseIcon }}</v-icon>
              </v-btn>
            </v-list>
          </v-row>
        </v-col>
        <!-- Other -->
        <div class="mx-auto">
          <p class="text-caption text-center">
            {{ currentTime }} / {{ duration }}
          </p>
          <v-progress-linear
            background-color="grey lighten-2"
            color="green darken-4"
            v-model="percentage"
            height="5"
            style="margin-top: 15px; margin-bottom: 15px"
            @click.native="setPosition()"
            :disabled="!loaded"
          ></v-progress-linear>

          <audio
            id="player"
            ref="player"
            v-on:ended="ended"
            v-on:canplay="canPlay"
            :src="team.audio"
          ></audio>
        </div>
      </v-container>

      <transition name="fade" mode="out-in">
        <!-- TEAM -->
        <div class="bg mt-1">
          <div class="mx-auto text-center">
            <p class="font-weight-bold">[ {{ team.formation }} ]</p>
          </div>

          <!-- team 11 -->
          <v-container>
            <!-- gk -->
            <ul class="py-5">
              <li class="text-center text-caption">
                <v-avatar class="mb-2" size="62">
                  <img :src="team.img_gk" class="avatar" />
                </v-avatar>
                <br />
                <span class="text-center text-player">{{
                  team.player_gk
                }}</span>
              </li>
            </ul>
            <!-- def -->
            <ul class="py-5">
              <li
                class="text-center text-caption"
                v-for="(player, $index) of team.img_def"
                :key="$index"
              >
                <v-avatar class="mb-2 avatar-margin" size="62">
                  <img :src="player" class="avatar" />
                </v-avatar>
                <br />
                <span class="text-center text-player">{{
                  team.player_def[$index]
                }}</span>
              </li>
            </ul>
            <!-- mid -->
            <ul class="py-5">
              <li
                class="text-center text-caption"
                v-for="(player, $index) of team.img_mid"
                :key="$index"
              >
                <v-avatar class="mb-2 avatar-margin" size="62">
                  <img :src="player" class="avatar" />
                </v-avatar>
                <br />
                <span class="text-center text-player">{{
                  team.player_mid[$index]
                }}</span>
              </li>
            </ul>

            <!-- fwd -->
            <ul class="py-5">
              <li
                class="text-center text-caption"
                v-for="(player, $index) of team.img_fwd"
                :key="$index"
              >
                <v-avatar class="mb-2 avatar-margin" size="62">
                  <img :src="player" class="avatar" />
                </v-avatar>
                <br />
                <span class="text-center text-player">{{
                  team.player_fwd[$index]
                }}</span>
              </li>
            </ul>
          </v-container>

          <!-- end team 11 -->
        </div>
      </transition>
    </v-container>
  </div>
</template>
<script>
import Firebase from "firebase";
const formatTime = (second) =>
  new Date(second * 1000).toISOString().substr(11, 8);

export default {
  name: "TeamDetail",
  beforeRouteEnter(to, from, next) {
    Firebase.firestore()
      .collection("equipos")
      .doc(to.params.id)
      .get()
      .then((document) => {
        next((viewModel) => {
          viewModel.team = { id: document.id, ...document.data() };
        });
      });
  },
  props: {
    flat: {
      type: Boolean,
      default: false,
    },

    ended: {
      type: Function,
      default: () => {},
    },
    canPlay: {
      type: Function,
      default: () => {},
    },

    playIcon: {
      type: String,
      default: "mdi-play",
    },
    pauseIcon: {
      type: String,
      default: "mdi-pause",
    },
  },
  computed: {
    duration: function () {
      return this.audio ? formatTime(this.totalDuration) : "";
    },
  },
  data: () => ({
    team: [],
    file: "`${{team.audio}}`",
    firstPlay: true,
    loaded: false,
    playing: false,
    paused: false,
    percentage: 0,
    currentTime: "00:00:00",
    audio: undefined,
    totalDuration: 0,
    playerVolume: 1,
  }),

  methods: {
    setPosition() {
      this.audio.currentTime = parseInt(
        (this.audio.duration / 100) * this.percentage
      );
    },
    play() {
      if (this.playing) return;
      // eslint-disable-next-line no-unused-vars
      this.audio.play().then((_) => (this.playing = true));
      this.paused = false;
    },
    pause() {
      this.paused = !this.paused;
      this.paused ? this.audio.pause() : this.audio.play();
    },
    _handleLoaded: function () {
      if (this.audio.readyState >= 2) {
        if (this.audio.duration === Infinity) {
          // Fix duration for streamed audio source or blob based
          // https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175
          this.audio.currentTime = 1e101;
          this.audio.ontimeupdate = () => {
            this.audio.ontimeupdate = () => {};
            this.audio.currentTime = 0;
            this.totalDuration = parseInt(this.audio.duration);
            this.loaded = true;
          };
        } else {
          this.totalDuration = parseInt(this.audio.duration);
          this.loaded = true;
        }

        if (this.autoPlay) this.audio.play();
      } else {
        throw new Error("Failed to load sound file");
      }
    }, // eslint-disable-next-line no-unused-vars
    _handlePlayingUI: function (e) {
      this.audio.volume = this.playerVolume;
      this.percentage = (this.audio.currentTime / this.audio.duration) * 100;
      this.currentTime = formatTime(this.audio.currentTime);
      this.playing = true;
    },
    _handlePlayPause: function (e) {
      if (e.type === "play" && this.firstPlay) {
        // in some situations, audio.currentTime is the end one on chrome
        this.audio.currentTime = 0;
        if (this.firstPlay) {
          this.firstPlay = false;
        }
      }
      if (
        e.type === "pause" &&
        this.paused === false &&
        this.playing === false
      ) {
        this.currentTime = "00:00:00";
      }
    },
    _handleEnded() {
      this.paused = this.playing = false;
    },
    init: function () {
      this.audio.addEventListener("timeupdate", this._handlePlayingUI);
      this.audio.addEventListener("loadeddata", this._handleLoaded);
      this.audio.addEventListener("pause", this._handlePlayPause);
      this.audio.addEventListener("play", this._handlePlayPause);
      this.audio.addEventListener("ended", this._handleEnded);
    },
  },
  mounted() {
    this.audio = this.$refs.player;
    this.init();
  },
  beforeDestroy() {
    this.audio.removeEventListener("timeupdate", this._handlePlayingUI);
    this.audio.removeEventListener("loadeddata", this._handleLoaded);
    this.audio.removeEventListener("pause", this._handlePlayPause);
    this.audio.removeEventListener("play", this._handlePlayPause);
    this.audio.removeEventListener("ended", this._handleEnded);
  },
};
</script>

<style scoped>
ul {
  text-align: center;
}
li {
  display: inline-block;
}

.avatar {
  object-fit: cover;
  object-position: top center;
  border: solid 0px black;
}

.bg {
  background-image: url(../../public/assets/img/soccer7.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.team-title {
  font-size: 3.4rem;
}

p,
.team-date {
  font-family: "Spartan", sans-serif;
}

.team-date {
  font-size: 0.6rem;
}

.text-player {
  font-family: "Spartan", sans-serif !important;
  font-size: 0.55rem !important;
  font-weight: 600 !important;
  color: rgb(29, 29, 29);
  text-transform: uppercase;
}

/* 360px and down */
@media only screen and (max-width: 375px) {
  .team-title {
    font-size: 1.8rem;
  }
  .avatar-margin {
    margin: 0 0.7rem;
  }
}

/* 400px and down */
@media only screen and (min-width: 400px) {
  .team-title {
    font-size: 2.3rem;
  }
  .avatar-margin {
    margin: 0 1.3rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .team-title {
    font-size: 2.7rem;
  }
  .avatar-margin {
    margin: 0 2rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .team-title {
    font-size: 3.4rem;
  }
  .avatar-margin {
    margin: 0 3rem;
  }
  .bg {
    background-image: url(../../public/assets/img/soccer7.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
